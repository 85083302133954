<template>
  <div class="types-box">
    <Header :title="'香港保险'" :back="true" :complete="'home'"/>
    <div class="types box-items">
      <div class="types-title">{{data?data.name:'-'}}</div>
      <div class="types-subtitle">
        <span v-if="data.name!=='万年青-传承'">{{data?data.sex:'-'}} </span>
        {{data?data.insured_age:'-'}}周岁
        <span v-if="data.name!=='万年青-传承'">{{data?data.smoke:'-'}} </span>
      </div>
      <div class="flex-box plan-msg">
        <div class="lf-tp">
          <div>年缴保费</div>
          <div class="theme-color big-size">${{data?data.total:'0'|format}}</div>
        </div>
        <div class="rg-tp">
          <div>保险金额</div>
          <div class="theme-color big-size">${{data?data.insured_amount:'0'|format}}</div>
        </div>
      </div>
      <div class="flex-box plan-msg">
        <div class="lf-bt">
          <div>缴费年限</div>
          <div class="theme-color big-size">{{data?data.insured_time:'0'}}</div>
        </div>
        <div class="rg-bt">
          <div>保险期限</div>
          <div class="theme-color big-size">终身</div>
        </div>
      </div>
    </div>
    <div class="box-items">
      <div class="types-subtitle flex-box age-box">
        <div v-if="data.name!=='万年青-传承'">被保人 {{data?data.insured_estimated_age:'-'}} 岁</div>
        <div v-else>被保人 {{data?Number(data.insured_age)+1:'-'}} 岁</div>
      </div>
      <div class="flex-box pc-box">
        <div>严重病况赔偿</div>
        <div>${{data?data.total1:'0'|format}} </div>
      </div>
      <div class="flex-box pc-box">
        <div>身故赔偿</div>
        <div>${{data?data.total1:'0'|format}} </div>
      </div>
      <div class="flex-box pc-box">
        <div>退保现金价值</div>
        <div>${{data?data.total2:'0'|format}}</div>
      </div>
    </div>
    <div class="box-items bz-box flex-box" v-for="item in jbList[0].list" :key="item.name">
      <div>
        {{item.name}}
      </div>
      <div>
        <div class="text-btn theme-color" @click="goDetail(item.name)">查看详情 ></div>
      </div>
    </div>
   
     <div class="gw">
      <button @click="handerGw" class="defined-btn">联系保险顾问</button>
     </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return {
      age:'',
      data:null,
      jbList:[],
      baozhang:[{
        name:'爱伴航',
        list:[{name:'58种危疾'},{name:'44种早期危疾'},{name:'首10年升级保障'}]
      },{
        name:'活耀人生危疾保2',
        list:[{name:'严重危疾'},{name:'早期危疾'},{name:'儿童疾病'}]
      },{
        name:'富通守护168加强版',
        list:[{name:'严重程度3'},{name:'严重程度2'},{name:'严重程度1'}]
      },{
        name:'危疾家康保',
        list:[{name:'危疾'},{name:'早期危疾'}]
      },{
        name:'万年青-传承',
        list:[{name:'投保说明'}]
      }]
    }
  },
  created(){
    this.data= JSON.parse(localStorage.getItem('plan_data'))
    this.jbList = this.baozhang.filter(item=>item.name == this.data.name)
  },
  methods:{
    handerGw(){
      window.location.href="tel:4000362988"
    },
    goDetail(type){
      this.$router.push({name:'insureitems',params:{type:type,name:this.data.name}})
    }
  }
}
</script>
<style lang="scss" scoped>
.types-box{
  padding-bottom:4.5rem
}
.types{
  margin:2.5%;
  padding-top:2rem;
  padding-bottom: 1rem;
  .types-title{
    font-size: 1.2rem;
    color: #666;
    letter-spacing: .2px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
  }
  .types-subtitle{
    color: #1D1D1D;
    font-size: 1.2rem;
    letter-spacing: .2px;
    font-weight: 700;
    text-align: center;
    margin-bottom: .6rem;
  }
  .plan-msg{
    width: 100%;
    justify-content: space-around;
    text-align: center;
    // line-height: 2;
    >div{
      width: 50%;
      padding: .6rem 0px;
      :first-child{
        font-size: .9rem;
        color:#666
       }
    }
    .lf-tp{
       border-right:1px solid #eee;
    }
    .lf-bt{
       border-right:1px solid #eee;
       border-top:1px solid #eee;
    }
    .rg-bt{
      border-top:1px solid #eee;
    }
  }
}
.pc-box{
  justify-content: space-between;
  line-height: 2;
  border-bottom:1px solid #eee;
  margin-top:.6rem;
  :first-child{
    color: #464646;
  }
}
.age-box{
  align-items: center;
  text-align: center;
  justify-content: center;
}
.text-btn{
  text-align: center;
  font-size: .9rem;
  cursor: pointer;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}
.bz-box {
  margin:2.5%;
  justify-content: space-between;
  align-items: center;
  // justify-items: center;
}

.gw{
  height: 4rem;
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom:0px;
  text-align: center;
  border-top:1px solid #eee;
  line-height: 4rem;
  .defined-btn{
    width: 90%;
  }
}
.big-size{
  font-size: 1.5rem;
}
</style>