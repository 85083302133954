var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "types-box" },
    [
      _c("Header", {
        attrs: { title: "香港保险", back: true, complete: "home" },
      }),
      _c("div", { staticClass: "types box-items" }, [
        _c("div", { staticClass: "types-title" }, [
          _vm._v(_vm._s(_vm.data ? _vm.data.name : "-")),
        ]),
        _c("div", { staticClass: "types-subtitle" }, [
          _vm.data.name !== "万年青-传承"
            ? _c("span", [_vm._v(_vm._s(_vm.data ? _vm.data.sex : "-") + " ")])
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.data ? _vm.data.insured_age : "-") + "周岁 "),
          _vm.data.name !== "万年青-传承"
            ? _c("span", [
                _vm._v(_vm._s(_vm.data ? _vm.data.smoke : "-") + " "),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "flex-box plan-msg" }, [
          _c("div", { staticClass: "lf-tp" }, [
            _c("div", [_vm._v("年缴保费")]),
            _c("div", { staticClass: "theme-color big-size" }, [
              _vm._v(
                "$" + _vm._s(_vm._f("format")(_vm.data ? _vm.data.total : "0"))
              ),
            ]),
          ]),
          _c("div", { staticClass: "rg-tp" }, [
            _c("div", [_vm._v("保险金额")]),
            _c("div", { staticClass: "theme-color big-size" }, [
              _vm._v(
                "$" +
                  _vm._s(
                    _vm._f("format")(_vm.data ? _vm.data.insured_amount : "0")
                  )
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "flex-box plan-msg" }, [
          _c("div", { staticClass: "lf-bt" }, [
            _c("div", [_vm._v("缴费年限")]),
            _c("div", { staticClass: "theme-color big-size" }, [
              _vm._v(_vm._s(_vm.data ? _vm.data.insured_time : "0")),
            ]),
          ]),
          _vm._m(0),
        ]),
      ]),
      _c("div", { staticClass: "box-items" }, [
        _c("div", { staticClass: "types-subtitle flex-box age-box" }, [
          _vm.data.name !== "万年青-传承"
            ? _c("div", [
                _vm._v(
                  "被保人 " +
                    _vm._s(_vm.data ? _vm.data.insured_estimated_age : "-") +
                    " 岁"
                ),
              ])
            : _c("div", [
                _vm._v(
                  "被保人 " +
                    _vm._s(_vm.data ? Number(_vm.data.insured_age) + 1 : "-") +
                    " 岁"
                ),
              ]),
        ]),
        _c("div", { staticClass: "flex-box pc-box" }, [
          _c("div", [_vm._v("严重病况赔偿")]),
          _c("div", [
            _vm._v(
              "$" +
                _vm._s(_vm._f("format")(_vm.data ? _vm.data.total1 : "0")) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "flex-box pc-box" }, [
          _c("div", [_vm._v("身故赔偿")]),
          _c("div", [
            _vm._v(
              "$" +
                _vm._s(_vm._f("format")(_vm.data ? _vm.data.total1 : "0")) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "flex-box pc-box" }, [
          _c("div", [_vm._v("退保现金价值")]),
          _c("div", [
            _vm._v(
              "$" + _vm._s(_vm._f("format")(_vm.data ? _vm.data.total2 : "0"))
            ),
          ]),
        ]),
      ]),
      _vm._l(_vm.jbList[0].list, function (item) {
        return _c(
          "div",
          { key: item.name, staticClass: "box-items bz-box flex-box" },
          [
            _c("div", [_vm._v(" " + _vm._s(item.name) + " ")]),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "text-btn theme-color",
                  on: {
                    click: function ($event) {
                      return _vm.goDetail(item.name)
                    },
                  },
                },
                [_vm._v("查看详情 >")]
              ),
            ]),
          ]
        )
      }),
      _c("div", { staticClass: "gw" }, [
        _c(
          "button",
          { staticClass: "defined-btn", on: { click: _vm.handerGw } },
          [_vm._v("联系保险顾问")]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rg-bt" }, [
      _c("div", [_vm._v("保险期限")]),
      _c("div", { staticClass: "theme-color big-size" }, [_vm._v("终身")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }